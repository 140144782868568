<template>
    <section class="content">
        <table class="table table-hover table-striped table-no-border" ref="tblkelas">
        <thead>
            <tr>
                <th>NAMA SISWA</th>
                <th>Tanggal</th>
                <th>TWK</th>
                <th>TIU</th>
                <th>TKP</th>
                <th>Total Score</th>
                <th>Lulus/Tidak Lulus</th>
            </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
        </table>
    </section>
    <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: "",
    data() {
        return {
        errors: [],
        method: "",
        roles: "",
        formTitle: "Tambah",
        dataUjian: '',
        form: {
            kelas: "",
        },
        date: ''
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
    },
    methods: {
        handleClick(e) {
            if (e.target.matches(".link-role")) {
                this.$router.push({ path: "/permit/" + e.target.dataset.id });
                return false;
            }
        },
    },
    mounted() {
    const e = this.$refs;
    let self = this;
    var filter = { mapel_id: this.$route.params.mapel_id, ujian_code: this.$route.params.ujian_code, program_id: this.$route.params.program_id, kelas_id: this.$route.params.kelas_id, paket_item: self.$route.params.paket_item };
    let data = new URLSearchParams(filter);

    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    self.date = dd+'-'+mm+'-'+yyyy;

    this.table = createTable(e.tblkelas, {
        title: '',
        roles: ["read"],
        ajax: "/siswa/ujian/ranking_web?"+ data,
        columns: [
            { data: "nama_siswa" },
            { data: "tgl" },
            { data: "twk" },
            { data: "tiu" },
            { data: "tkp" },
            { data: "final_score", sortable:false },
            { data: "lulus", sortable:false },
        ],
        aLengthMenu: [
            [25, 50, 100, 200, 300, -1],
            [25, 50, 100, 200, 300, 'ALL']
        ],
        // filterBy: [0],
        filter: false,
        paging: this.$route.params.kelas_id == 0 ? true : false,
        rowCallback: function (row, data) {
        // $('td:eq(2)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'" data-branch="'+data.branch_id+'">Data Siswa</a>');
        },
        initComplete: function() {
            authFetch("/siswa/ujian/ujian_info/" + self.$route.params.ujian_code).then((res) => {
                res.json().then((json) => {
                  self.dataUjian = json;
                  self.formTitle = json.data.ujian_title;
                  $('.card-title').html(json.data.ujian_title)
                });
              });
        },
        dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          {
              extend: 'excel',
              text: 'XLSX',
              className: 'btn btn-success',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return self.formTitle+' ('+self.date+')';
              },
              filename: function(){
                  return self.formTitle+' ('+self.date+')';
              },
          },
          {
              extend: 'print',
              text: 'Print',
              className: 'btn btn-primary',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return self.formTitle+' ('+self.date+')';
              },
              filename: function(){
                  return self.formTitle+' ('+self.date+')';
              },
          },
          {
              extend: 'pdf',
              text: 'PDF',
              className: 'btn btn-info',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return self.formTitle+' ('+self.date+')';
              },
              filename: function(){
                  return self.formTitle+' ('+self.date+')';
              },
          },
          {
              extend: 'csvHtml5',
              text: 'CSV',
              className: 'btn bg-gray-dark',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return self.formTitle+' ('+self.date+')';
              },
              filename: function(){
                  return self.formTitle+' ('+self.date+')';
              },
          },
        ],
    });
},
};
</script>